import React from 'react';
import UpNext from '@webMolecules/UpNext/UpNext';
import Design from '@localTypes/Design';
import FloorPlanDetailed from '@localTypes/FloorPlanDetailed';
import Hero from './Sections/Hero';
import FloorPlans from './Sections/FloorPlans';
import Intro from './Sections/Intro';
import PaletteOptions from './Sections/PaletteOptions';
import YouMayAlsoLike from '@webOrganisms/YouMayAlsoLike/YouMayAlsoLike';
import BrochureForm from '@webSections/BrochureForm';
import * as Route from '@routes';

export interface ProductProps {
  design: Design;
  floorPlans: FloorPlanDetailed[];
}

const Product: React.FC<ProductProps> = ({ design, floorPlans }) => {
  return (
    <>
      <Hero design={design} />

      {floorPlans.length > 0 ? (
        <FloorPlans flyerPdf={design.flyerPdf} floorPlans={floorPlans} />
      ) : null}
      <Intro design={design} />

      <PaletteOptions design={design} />

      <BrochureForm />

      <YouMayAlsoLike exclude={design.slug} />

      <UpNext
        links={[
          { label: 'Our process', href: Route.ourProcess() },
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
        ]}
      />
    </>
  );
};

export default Product;
